<template>
  <!-- 作业考试成绩 -->
  <div class="main-contain">
    <el-table :data="tableData">
      <el-table-column prop="name" label="名称" min-width="180"></el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">{{
          scope.row.type | typeformat
        }}</template>
      </el-table-column>
      <el-table-column prop="end_time" label="截止时间" min-width="160">
        <template slot-scope="scope">{{
          scope.row.end_time | dataformat
        }}</template>
      </el-table-column>
      <el-table-column prop="user_score" label="成绩">
        <template slot-scope="scope">
          <div v-if="scope.row.test_period_user_status">
            {{ scope.row.user_score }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="test_period_user_status"
        label="提交时间"
        min-width="160"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.test_period_user_status">
            {{ scope.row.user_end_time | dataformat }}
          </div>
          <div v-else>—</div>
        </template>
      </el-table-column>
      <el-table-column prop="test_period_user_status" label="状态">
        <template slot-scope="scope">{{
          scope.row.test_period_user_status | stateformat
        }}</template>
      </el-table-column>
      <el-table-column prop="reason" label="驳回理由" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.test_period_user_status == 2">{{
            scope.row.reason
          }}</span>
          <span v-else>—</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="item-right">
            <template v-if="scope.row.test_period_user_status == 1">
              <div
                class="bt empty"
                @click="toDetail(scope.row, scope.row.status)"
              >
                详情
              </div>
            </template>
            <template v-else>
              <template v-if="scope.row.access_status">
                <div
                  class="bt"
                  @click="toanswer(scope.row.test_period_id, scope.row.status)"
                >
                  答题
                </div>
              </template>
              <template v-else>无权限</template>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <pag-table
      background
      layout="prev, pager, next"
      :total="total"
      :page="page"
      :limit="per_page"
      @changeSize="changeSize"
      @changePage="changePage"
      style="margin-top: 10px; text-align: center"
    />

    <!-- 试卷详情 -->
    <Customdialog
      ref="dialog_testdetail"
      type="min"
      class="customdialog"
      :title="testTitle"
      :width="widthdialog"
      :showclose="true"
    >
      <div slot="dialogbody" class="dialogbody">
        <div class="my_test">
          <div class="remark" v-if="isRightness">
            老师评语：{{ testInfo.user_remark }}
          </div>
          <div v-for="(item, index) in testStemList" :key="index">
            <div class="topic_type">
              <div class="order">{{ AutoNumberByIndex(item.type - 1) }}</div>
              <div class="classify">{{ item.type | stemformat }}</div>
              <div class="score">
                （{{ item.child.length }}题，共{{ item.score }}分）
              </div>
            </div>
            <div class="subject" v-for="(stem, si) in item.child" :key="si">
              <div class="question">
                <div class="order">{{ si + 1 }},</div>
                <div class="score">({{ stem.score }}分)</div>
                <div class="title" v-html="stem.title"></div>
              </div>
              <template v-if="item.type < 3">
                <div
                  class="option"
                  v-for="(option, oi) in stem.option"
                  :key="oi"
                  :style="item.type == 3 ? 'margin-bottom:10px' : ''"
                >
                  <div
                    :class="
                      option.is_check
                        ? 'order qs_right qs_active'
                        : 'order qs_right'
                    "
                  >
                    {{ AutoNumberByLetter(oi) }}
                  </div>
                  <div class="content" v-html="option.content"></div>
                </div>
              </template>
              <template v-if="stem.user_answer && stem.user_answer.answer">
                <div class="user_answer">
                  <div class="imgAnalysis" v-if="isRightness">
                    <img
                      v-if="stem.user_answer.is_right"
                      src="@/assets/right.png"
                    />
                    <img v-else src="@/assets/wrong.png" />
                  </div>
                  <div>
                    <div class="user_section">
                      <div class="section_title">学生答案：</div>
                      <div class="answer" v-if="stem.user_answer.answer">
                        <template v-if="stem.user_answer.answer.length === 0">
                          <span style="color: red">[未答题]</span>
                        </template>
                        <template v-else>
                          <div
                            class="answer_item"
                            v-for="(answer, ai) in stem.user_answer.answer"
                            :key="ai"
                          >
                            <template v-if="item.type < 4">
                              <div
                                v-for="(option, oi) in stem.option"
                                :key="oi"
                              >
                                <div
                                  v-if="answer && answer == option.id"
                                  style="min-width: 30px; margin: 0 5px"
                                >
                                  <span v-if="item.type == 3">{{
                                    oi == 0 ? "正确" : "错误"
                                  }}</span>
                                  <span v-else>{{
                                    AutoNumberByLetter(oi)
                                  }}</span>
                                </div>
                              </div>
                            </template>
                            <template v-if="item.type == 4">
                              <div
                                v-if="answer"
                                style="
                                  display: flex;
                                  min-width: 30px;
                                  margin: 0 5px;
                                "
                              >
                                <span>{{ ai + 1 }}、</span>
                                <span v-html="answer"></span>
                              </div>
                            </template>
                            <template v-if="item.type > 4">
                              <div
                                v-if="answer"
                                v-html="answer"
                                style="min-width: 30px; margin: 0 5px"
                              ></div>
                            </template>
                          </div>
                        </template>
                      </div>
                      <div
                        class="score"
                        v-if="isRightness"
                        style="margin-left: 10px"
                      >
                        得分：{{
                          stem.user_answer.score ? stem.user_answer.score : 0
                        }}
                      </div>
                    </div>
                    <div
                      class="user_section"
                      v-if="stem.user_answer.file_list.length > 0"
                    >
                      <div class="section_title">附件：</div>
                      <div class="file_list">
                        <div
                          class="file"
                          v-for="(file, fo) in stem.user_answer.file_list"
                          :key="fo"
                          @click="appendix(file.url)"
                        >
                          {{ file.name }}
                        </div>
                      </div>
                    </div>
                    <div class="remark" v-if="stem.user_answer.remark">
                      评语：{{ stem.user_answer.remark }}
                    </div>
                    <div class="user_section" v-if="isRightness">
                      <div class="section_title">标准答案：</div>
                      <div class="rightness">
                        <div
                          class="answer_item"
                          v-for="(option, oi) in stem.option"
                          :key="oi"
                        >
                          <div v-if="option.is_right">
                            <span v-if="item.type < 3">{{
                              AutoNumberByLetter(oi)
                            }}</span>
                            <span v-else-if="item.type == 3">{{
                              oi == 0 ? "正确" : "错误"
                            }}</span>
                            <span
                              v-else-if="item.type == 4"
                              style="display: flex; margin-right: 5px"
                            >
                              <span>{{ oi + 1 }}、</span>
                              <span v-html="option.content"></span>
                            </span>
                            <span v-else v-html="option.content"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter"></div>
    </Customdialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Customdialog from "@/components/customdialog.vue";
import pagTable from "@/components/Pagination/pagTable";
import { gettestperiodList } from "@/api/testperiod";
import { gettestperioduserInfo } from "@/api/testperioduser";
import { Base64 } from "js-base64";
const request_base_path = require("@/utils/base_url");
const typeMap = ["其他", "作业", "考试"];
const stateMap = {
  0: "未提交",
  1: "已提交",
  2: "已退回",
};
// 题目类型：0：其他，1：单选，2：多选，3：判断，4：填空，5：简答
const stemMap = {
  0: "其他题",
  1: "单选题",
  2: "多选题",
  3: "判断题",
  4: "填空题",
  5: "简答题",
};
export default {
  name: "learnprogress",
  components: {
    pagTable,
    Customdialog,
  },
  filters: {
    typeformat(val) {
      return typeMap[val];
    },
    stateformat(val) {
      return stateMap[val];
    },
    stemformat(status) {
      return stemMap[status];
    },
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  data() {
    return {
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      related_id: "",
      course_sn: "",
      tableData: [],
      per_page: 10, // 每页条数
      page: 1,
      total: 0,

      testTitle: "", // 查看试卷的名称
      testInfo: {},
      testStemList: [], // 试卷试题库
      isRightness: false, // 是否有正解
      filePreview: "", // 数据预览
      picturesPreview: "", // 多图预览
      fileTask: "", // 数据预埋
    };
  },
  created() {			
    const systemConfig = sessionStorage.getItem("systemConfig");
    const systemInfo = systemConfig ? JSON.parse(systemConfig) : {};
    if (systemInfo.file_preview_url) {
      this.filePreview = systemInfo.file_preview_url + "/onlinePreview?url="; // 数据预览
      this.picturesPreview = systemInfo.file_preview_url + "/picturesPreview?urls="; // 多图预览
      this.fileTask = systemInfo.file_preview_url + "/addTask?url=" // 数据预埋
    } else {
      this.filePreview = request_base_path.pro_url + "/file-preview/onlinePreview?url=" // 数据预览
      this.picturesPreview = request_base_path.pro_url + "/file-preview/picturesPreview?urls=" // 多图预览
      this.fileTask = request_base_path.pro_url + "/file-preview/addTask?url=" // 数据预埋
    }
    // this.gettestperiodList()
    if (this._isMobile()) {
      this.widthdialog = "100%";
    } else {
      this.widthdialog = "60%";
    }
  },
  methods: {
    // 判断手机端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 进入页面加载口
    initdata(obj, courseKey) {
      // console.log(obj, courseKey);
      this.course_sn = this.$route.query.course_sn;
      this.related_id = this.$route.query.id;
      this.gettestperiodList();
    },
    gettestperiodList() {
      let params = {
        per_page: this.per_page, // 每页条数
        page: this.page,
        related_id: this.related_id,
        id_type: 1,
      };
      this.tableData = [];
      gettestperiodList(params)
        .then((res) => {
          if (res.code === 0 && res.data) {
            this.tableData = res.data.data ?? [];
            this.total = res.data.total ?? 0;
          }
        })
        .catch(() => {});
    },
    // 分页
    changeSize(data) {
      this.per_page = parseInt(data);
      this.gettestperiodList();
    },
    changePage(data) {
      this.page = parseInt(data);
      this.gettestperiodList();
    },
    // 回答
    toanswer(id, status) {
      if (status == 2) {
        this.$message({
          message: "已过截止时间,无法答题",
          type: "warning",
        });
      } else {
        this.$router.replace({
          path: "/home/wisdompracticeteaching/answer",
          query: {
            test_period_id: id,
            course_sn: this.course_sn,
            page_type: 1,
          },
        });
      }
    },
    // 详情
    toDetail(row) {
      this.isRightness = false; // 起始关闭
      this.testTitle = row.test_name; // 获取当前试卷名
      let params = { test_period_id: row.test_period_id };
      gettestperioduserInfo(params).then((res) => {
        if (res.code === 0) {
          this.testInfo = JSON.parse(JSON.stringify(res.data));
          delete this.testInfo.stem_list;
          // 格式化数据
          let stemList = res.data.stem_list.map((item) => {
            // 问题
            item.title = this.AutoCorrection(item.title);
            // 答案
            if (item.option && item.option.length > 0) {
              for (let i = 0; i < item.option.length; i++) {
                let str = item.option[i].content;
                item.option[i].content = this.AutoCorrection(str);
                if (item.option[i].is_right) {
                  this.isRightness = true;
                }
              }
            }
            // 学生答案
            if (item.user_answer.answer) {
              if (item.user_answer.answer.length >= 0) {
                for (let i = 0; i < item.user_answer.answer.length; i++) {
                  let str = item.user_answer.answer[i];
                  item.user_answer.answer[i] = this.AutoCorrection(str);
                }
              } else {
                let keylist = Object.keys(item.user_answer.answer); // 获取对象全部key值
                for (let i = 0; i < keylist.length; i++) {
                  let key = keylist[i];
                  let str = item.user_answer.answer[key];
                  item.user_answer.answer[key] = this.AutoCorrection(str);
                }
              }
            }
            return item;
          });

          this.testStemList = []; // 清空上一个试卷数据
          // 更改数组结构
          if (stemList && stemList.length > 0) {
            this.formattingData(this.testStemList, stemList); // 生成新数组
            // type为属性名-题目类型
            this.testStemList.sort(this.compare("type")); // 按type字段重新排序
          }
          this.$refs.dialog_testdetail.dialogopenbt(); // 打开试卷查看窗口
        }
      });
    },
    // 处理数组-把一个数组拆分为多个数组
    formattingData(arr, data) {
      // 新数组、原数组
      data.forEach((item) => {
        item.active = false; // 设置默认无点击状态
        const parent = arr.find((cur) => cur.type === item.type);
        if (parent) {
          parent.child.push(item);
          // 计算分数
          parent.score += item.score;
        } else {
          const obj = {
            type: item.type,
            score: item.score,
            child: [item],
          };
          arr.push(obj);
        }
      });
    },
    // 数值排序
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    // 循环修正数据与数据格式
    AutoCorrection(str) {
      const that = this;
      let newStr = str.replaceAll(/&nbsp;/gi, ""); //去除空格字符
      newStr = newStr.replace(/[\r\n]/g, "").trim(); // 去与换行与前后空格
      newStr = newStr.replace(/\\/g, ""); // 去除反斜杠
      newStr = newStr.replace(/\s+/g, " "); // 去除多余空格
      // newStr = newStr.replace(/<p>(\s|\&nbsp\;| |　|\xc2\xa0)*<\/p>/ig, ""); // 去掉多余 P 标签
      // 判断是否有图片，如果有图片
      let isHavePic = newStr.includes("<img");
      if (isHavePic) {
        // 正则过滤出图片
        newStr.replace(/<img.+?>/gi, function (matchImg, captureImg) {
          //console.log("正则匹配所有图片并获取相应下标==>",matchImg, captureImg);
          // newStr = newStr.replace(match,""); // 清除图片
          let resImgSrc = ""; // 自定义变量接收图片
          matchImg.replace(
            /<img[^>]*src=['"]([^'"]+)[^>]*>/gi,
            function (matchSrc, captureSrc) {
              //console.log("正则匹配该图片并获取相应路径==>",matchSrc, captureSrc);
              resImgSrc = captureSrc.replace(
                "../uploads",
                that.$api.baseUrl + "/uploads"
              );
            }
          );
          // 在图片中插入点击事件并默认不展示
          let newImgStr = matchImg.replace(
            /<s?img[^>]*src=['"]([^'"]+)/gi,
            `<img src="${resImgSrc}" style="max-width:80%; margin: 4px;"`
          );
          newStr = newStr.replace(matchImg, newImgStr);
        });
      }
      // 判断是否有视频，如果有视频
      let isHaveMove = newStr.includes("<video");
      if (isHaveMove) {
        // 正则过滤出视频
        newStr.replace(
          /(<video[\s\S]*?>)[\s\S]*?(<\/video>)/gi,
          function (matchVideo, captureVideo) {
            // console.log("正则匹配所有视频并获取相应下标==>",matchVideo, captureVideo);
            // newStr = newStr.replace(matchVideo,""); // 清空视频
            let resMoveurl = ""; // 自定义变量接收视频
            matchVideo.replace(
              /<source [^>]*src=['"]([^'"]+)[^>]*>/gi,
              function (matchUrl, captureUrl) {
                // console.log("正则匹配该视频并获取相应路径==>",matchUrl, captureUrl);
                resMoveurl = captureUrl.replace(
                  "../uploads",
                  that.$api.baseUrl + "/uploads"
                );
              }
            );
            let newMoveStr = matchVideo.replace(
              /<s?video[\s\S]*?>[\s\S]*?<\/video>/gi,
              `<video controls="controls" style="max-width:80%; margin: 4px;"><source src="${resMoveurl}" type="video/mp4"/></video>`
            );
            newStr = newStr.replace(matchVideo, newMoveStr);
          }
        );
      }
      return newStr;
    },
    // 大写数字自动编号
    AutoNumberByIndex(vNumber) {
      var vLetter = [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
      ];
      if (vNumber < 10) {
        return vLetter[vNumber];
      }
    },
    // 字母自动编号
    AutoNumberByLetter(vNumber) {
      var vLetter = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      if (vNumber < 26) {
        return vLetter[vNumber];
      } else {
        var vInt = parseInt((vNumber + 1) / 26);
        var vRemainder = (vNumber + 1) % 26;
        if (vRemainder == 0) {
          vInt = vInt - 1;
          vRemainder = 26;
        }
        return vLetter[vInt - 1] + vLetter[vRemainder - 1];
      }
    },
    //附件在线查看
    appendix(url) {
      window.open(
        this.filePreview + encodeURIComponent(Base64.encode(url)),
        "_bank"
      );
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .main-contain {
    margin-top: 26px;
    background: #ffffff;
    padding: 20px;

    .item-right {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .bt {
        width: 72px;
        line-height: 30px;
        height: 30px;
        font-size: 14px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        font-weight: 400;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
      }

      .empty {
        background-color: transparent;
        border: 1px solid #3d84ff;
        box-sizing: border-box;
        color: #3d84ff;
        line-height: 30px;
        height: 30px;
        font-size: 14px;
      }
    }
  }

  .my_test {
    display: block;
    max-width: 100%;
    padding: 5px 10px;
    .remark {
      font-size: 16px;
      padding: 10px;
      box-shadow: 0px 0px 2px 0px #00000050;
    }
    .order {
      margin-right: 10px;
    }
    .topic_type {
      font-size: 16px;
      height: 50px;
      display: flex;
      align-items: center;
      .classify {
        font-weight: 700;
        margin-right: 10px;
      }
    }
    .subject {
      display: block;
      max-width: 100%;
      padding: 5px 10px;
      .question {
        font-size: 14px;
        min-height: 40px;
        display: flex;
        // align-items: baseline;
        .score {
          min-width: 40px;
        }
        .title {
          ::v-deep p {
            padding: 0;
            margin: 0;
            margin-bottom: 5px;
          }
        }
      }
      .option {
        font-size: 14px;
        min-height: 60px;
        display: flex;
        align-items: center;
        .qs_right {
          width: 30px;
          height: 30px;
          min-width: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 50%;
          background: #ffffff;
          border: 1px solid #d8d8d8;
        }
        .qs_active {
          color: #ffffff;
          border-color: #2a7cec;
          background: #2a7cec;
          opacity: 0.9;
        }
        .content {
          ::v-deep p {
            padding: 0;
            margin: 0;
            margin-bottom: 5px;
          }
        }
      }
      .user_answer {
        max-width: 100%;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px 0px #00000050;
        display: flex;
        align-items: flex-start;
        .remark {
          font-size: 14px;
          padding: 0px;
          box-shadow: none;
        }
        .imgAnalysis {
          width: 30px;
          height: 30px;
          margin: 5px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .user_section {
          max-width: 100%;
          display: flex;
          flex-wrap: wrap;
          .section_title {
            min-width: 80px;
          }
        }
        .answer_item {
          ::v-deep p {
            padding: 0;
            margin: 0;
            margin-bottom: 5px;
          }
        }
        .answer {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
        }
        .file_list {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
        }
        .rightness {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .main-contain {
    margin-top: 26px;
    background: #ffffff;
    padding: 20px;

    .item-right {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .bt {
        width: 72px;
        line-height: 30px;
        height: 30px;
        font-size: 14px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        font-weight: 400;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
      }

      .empty {
        background-color: transparent;
        border: 1px solid #3d84ff;
        box-sizing: border-box;
        color: #3d84ff;
        line-height: 30px;
        height: 30px;
        font-size: 14px;
      }
    }
  }

  .my_test {
    display: block;
    max-width: 100%;
    padding: 5px 10px;
    .remark {
      font-size: 16px;
      padding: 10px;
      box-shadow: 0px 0px 2px 0px #00000050;
    }
    .order {
      margin-right: 10px;
    }
    .topic_type {
      font-size: 16px;
      height: 50px;
      display: flex;
      align-items: center;
      .classify {
        font-weight: 700;
        margin-right: 10px;
      }
    }
    .subject {
      display: block;
      max-width: 100%;
      padding: 5px 10px;
      .question {
        font-size: 14px;
        min-height: 40px;
        display: flex;
        // align-items: baseline;
        .score {
          min-width: 40px;
        }
        .title {
          ::v-deep p {
            padding: 0;
            margin: 0;
            margin-bottom: 5px;
          }
        }
      }
      .option {
        font-size: 14px;
        min-height: 30px;
        display: flex;
        align-items: center;
        .qs_right {
          width: 30px;
          height: 30px;
          min-width: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 50%;
          background: #ffffff;
          border: 1px solid #d8d8d8;
        }
        .qs_active {
          color: #ffffff;
          border-color: #2a7cec;
          background: #2a7cec;
          opacity: 0.9;
        }
        .content {
          ::v-deep p {
            padding: 0;
            margin: 0;
            margin-bottom: 5px;
          }
        }
      }
      .user_answer {
        max-width: 100%;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px 0px #00000050;
        display: flex;
        align-items: flex-start;
        .remark {
          font-size: 14px;
          padding: 0px;
          box-shadow: none;
        }
        .imgAnalysis {
          width: 30px;
          height: 30px;
          margin: 5px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .user_section {
          max-width: 100%;
          display: flex;
          flex-wrap: wrap;
          .section_title {
            min-width: 80px;
          }
        }
        .answer_item {
          ::v-deep p {
            padding: 0;
            margin: 0;
            margin-bottom: 5px;
          }
        }
        .answer {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
        }
        .file_list {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
        }
        .rightness {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
        }
      }
    }
  }
}
</style>
